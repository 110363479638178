import {Pipe, PipeTransform} from '@angular/core';
import { UserRole } from '@core/models/models';
import {Role} from '@views/pages/profile/models/user.model';

@Pipe({
  name: 'roleLabel'
})
export class GetRoleLabelPipe implements PipeTransform {

  transform(role: Role | UserRole): string | Role {
   return RolesLabel[role] ? RolesLabel[role] : role
  }

}

export const RolesLabel = {
    ROLE_USER: "Utilisateur",
    ROLE_ADMIN: "Administrateur de la plateforme",
    ROLE_SUPER_ADMIN: "Super Administrateur",
    ROLE_ORGANIZATION: "Organisateur",
    ROLE_ORGANIZATION_ADMIN: "Administrateur d\'organisation",
    ROLE_ORGANIZATION_DEFAULT: "Organisateur",
    ROLE_ORGANIZATION_DEFAULTS: "Organisateur",
    ROLE_ORGANIZATION_BENEFIT: "ROLE_ORGANIZATION_BENEFIT",
    ROLE_ORGANIZATION_INSURANCE: "ROLE_ORGANIZATION_INSURANCE",
    ROLE_ORGANIZATION_CONTRIBUTION: "ROLE_ORGANIZATION_CONTRIBUTION",
    ROLE_ORGANIZATION_HEALTH_CENTER: "ROLE_ORGANIZATION_HEALTH_CENTER",  
}