<a
    (click)="handleMainMenuAction()"
    class="nav-link"
    [ngClass]="{active: isMainActive || isOneOfChildrenActive}"
>
    <i class="nav-icon" [attr.class]="menuItem?.icon"></i>
    <p class="ms-2">{{ menuItem.name }}</p>
    <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-left"
    ></i>
</a>
<ul class="nav nav-treeview" *ngFor="let item of menuItem.children">
    <li class="nav-item">
        <a
            [routerLink]="item.path"
            [routerLinkActive]="'active'"
            class="nav-link"
        >
            <i class="fa fa-circle nav-icon"></i>
            <p>{{ item.name }}</p>
        </a>
    </li>
</ul>
