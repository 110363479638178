export const MENU = [
  {
    name: 'Tableau de bord',
    path: ['/dashboard'],
    icon: 'fas fa-home',
  },
  // {
  //   name: 'Organisations',
  //   path: ['/organisation'],
  //   icon: 'fa fa-sitemap',
  // },
  // {
  //   name: 'Utilisateurs',
  //   path: ['/utilisateurs'],
  //   icon: 'fas fa-users'
  // },
  {
    name: 'Référentiels',
    path: ['/referentiels'],
    icon: 'fas fa-code-branch',
  },
  {
    name: 'Transmissions',
    path: ['/transmissions'],
    icon: 'fa fa-exchange-alt',
  },
  {
    name: 'Flux',
    path: ['/flux'],
    icon: 'fa fa-route',
  },
  {
    name: 'Adjudications',
    path: ['/adjudications'],
    icon: 'fas fa-hand-holding-medical',
  },
  {
    name: 'Audit-trail',
    path: ['/audit-trail'],
    icon: 'fas fa-stream',
  },
];

export const ORGANIZATION_ADMIN_MENU = [
  {
    name: 'Tableau de bord',
    path: ['/dashboard'],
    icon: 'fas fa-home',
  },
  {
    name: 'Transmissions',
    path: ['/transmissions'],
    icon: 'fa fa-exchange-alt',
  },
  {
    name: 'Référentiels',
    path: ['/referentiels'],
    icon: 'fas fa-code-branch',
  },
];

export const ORGANIZATION_MANAGEMENT_MENU = [
  {
    name: 'Organisations',
    path: ['/organisation'],
    icon: 'fa fa-sitemap',
  },
  {
    name: 'Utilisateurs',
    path: ['/utilisateurs'],
    icon: 'fas fa-users',
  },
];

export const MenuOrganization = [
  {
    name: 'Tableau de bord',
    path: ['/dashboard'],
    icon: 'fas fa-home'
  },
 /* {
    name: 'Organisations',
    path: ['/organisation'],
    icon: 'fa fa-sitemap',
  },*/
  {
    name: 'Référentiels',
    path: ['/referentiels'],
    icon: 'fas fa-exclamation-circle'
  },
];
