import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@core/services/api/api-service/api-service";
import {environment} from "@environments/environment";
import {CheckUserRole} from "@core/utils/check-user-role";

@Injectable({
  providedIn: "root"
})
export class IdentityService extends ApiService {

  constructor(public http: HttpClient, private checkUserRole: CheckUserRole) {
    super();
  }


  protected getUrl() {
    // if (this.checkUserRole.isOrganizationAdmin()) {
    //   return `${environment?.apiBaseUrl}/identity/v1/rest/me/organizations`;
    // }
    return `${environment?.apiBaseUrl}/identity/v1/rest`;
  }

  protected getHttp(): HttpClient {
    return this.http;
  }
}
