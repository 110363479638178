import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from "@core/services/session.service";
import {IUser} from '@views/pages/profile/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    if (this.sessionService.isAuthenticatedUser()) {
      // logged in so return true
      const user = this.sessionService.getAuthenticatedUser().user as IUser;
      if(user.passwordChanged) {
        return true;
      }

      this.router.navigate(['/se-connecter/change-password'], { queryParams: { returnUrl: state.url } });
      return false

    }
    this.router.navigate(['/se-connecter']);
    // this.router.navigate(['/se-connecter'], { queryParams: { returnUrl: state.url } });
    // not logged in so redirect to login page
    return false;
  }

}

