import {Injectable} from '@angular/core';
import {catchError, map, Observable, of, throwError} from 'rxjs';
import {IdentityService} from "@core/services/api/identity/identity.service";
import { UserProfile } from '@core/models/models';
import { IOrganization } from '../models/organization.model';
import { SessionService } from '@core/services/session.service';
import { Role } from '@views/pages/profile/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private endpoint = {
    organizations: 'organizations'
  };

  currentOrganization?: IOrganization;

  constructor(
    private identityService: IdentityService,
    private sessionService: SessionService
  ) { }

  public getOrganizations(params?: any): Observable<any> {
    return this.identityService.get(`${this.endpoint.organizations}`, params).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    );
  }

  public getOrganization(organizationId: any, params?: any) : Observable<any> {
    return this.identityService.get(`${this.endpoint.organizations}/${organizationId}`, params).pipe(
      map((res: any) => {
        this.currentOrganization = res;
        return res
      }),
      catchError(error => throwError(() => error))
    )
  }

  public createOrganization(data: any): Observable<any> {
    return this.identityService.post(`${this.endpoint.organizations}`, data).pipe(
      map((res:any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public updateOrganization(data: any, organizationId: any): Observable<any> {
    return this.identityService.put(`${this.endpoint.organizations}/${organizationId}`, data).pipe(
      map((res:any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public listUserIntoOrganization(organizationId: string, params?: any) {
    return this.identityService.get(`${this.endpoint.organizations}/${organizationId}/users`, params);
  }

  public createUserIntoOrganization(organizationId: string, data: Partial<UserProfile>) {
    return this.identityService.post(`${this.endpoint.organizations}/${organizationId}/users`, data);
  }

  public readUserIntoOrganization(organizationId: string, userId: string): Observable<any> {
    return this.identityService.get(`${this.endpoint.organizations}/${organizationId}/users/${userId}`);
  }

  public updateUserIntoOrganization(organizationId: string, userId: string, data: Partial<UserProfile>) {
    return this.identityService.put(`${this.endpoint.organizations}/${organizationId}/users/${userId}`, data);
  }

  public deleteUserIntoOrganization(organizationId: string, userId: string) {
    return this.identityService.delete(`${this.endpoint.organizations}/${organizationId}/users/${userId}`);
  }

  public addUserIntoOrganization(organizationId: any, data: any) {
    return this.identityService.put(`${this.endpoint.organizations}/${organizationId}/users`, data);
  }

  public promoteUserIntoOrganization(organizationId: string, userId: string) {
    return this.identityService.put(`${this.endpoint.organizations}/${organizationId}/users/${userId}/promote`, null);
  }

  public demoteUserIntoOrganization(organizationId: string, userId: string) {
    return this.identityService.put(`${this.endpoint.organizations}/${organizationId}/users/${userId}/demote`, null);
  }

  public deleteOrganization(organizationId: any, params?:any): Observable<any> {
    return this.identityService.delete(`${this.endpoint.organizations}/${organizationId}`, params).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public changeStatus(data: any, organizationId: any) : Observable<any> {
    return this.identityService.patch(`${this.endpoint.organizations}/${organizationId}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    )
  }

  haveInsuranceRole() {
    const role = Role;
    if (!!this.currentOrganization) {
      const canAccess =
      this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_DEFAULTS) ||
      this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_BENEFIT) ||
      this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_INSURANCE);
      return of(canAccess);
    }

    const organizationId = this.sessionService.getAuthenticatedUser().user?.organization?.id;
    if (!organizationId) {
      return of(false);
    }

    return this.getOrganization(organizationId).pipe(
      map(res => {
        this.currentOrganization = res;
        const canAccess = !!(this.currentOrganization
          && (
          this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_DEFAULTS) ||
          this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_BENEFIT) ||
          this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_INSURANCE)));
          return canAccess;
      })
    );
  }

  haveHealthRole() {
    const role = Role;
    if (!!this.currentOrganization) {
      const canAccess =
      this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_HEALTH_CENTER);
        return of(canAccess);
    }

    const organizationId = this.sessionService.getAuthenticatedUser().user?.organization?.id;
    if (!organizationId) {
      return of(false);
    }

    return this.getOrganization(organizationId).pipe(
      map(res => {
        this.currentOrganization = res;
        const canAccess = !!(this.currentOrganization
          && (
          this.currentOrganization.roles.includes(role.ROLE_ORGANIZATION_HEALTH_CENTER)));
          return canAccess;
      })
    );
  }

  clearOrganizationData() {
    this.currentOrganization = undefined;
  }
}

