
<app-dropdown class="user-menu">
    <ng-container dropdown-button>
        <section *ngIf="user?.firstName && user?.lastName" class="alias">
            <article  class="alias__name" placement="bottom"
                [ngbTooltip]="user.firstName + ' ' + user.lastName">
                <span>{{ user!.lastName | slice : 0 : 1
                    }}{{user!.firstName | slice : 0 : 1 }}</span>
            </article>
            <article class="alias__menu">
                <div mat-button>
                    <h5 *ngIf="user?.organization?.name">
                        {{ user?.organization?.name }}
                    </h5>
                    <h5 *ngIf="!user?.organization?.name">
                        {{ user?.username }}
                    </h5>
                    <h6>
                        {{ user?.roles | mainRoleLabel }}
                    </h6>
                </div>
                <i class="fa fa-chevron-down fa-2xs"></i>
            </article>
        </section>

        <img
            *ngIf="!user?.firstName || !user?.lastName"
            [src]="user?.picture || 'assets/img/default-profile.png'"
            class="user-image img-circle elevation-2"
            alt="User Image"
        />
    </ng-container>
    <ng-container dropdown-menu>
        <li class="user-header bg-primary">
            <img
                [src]="user?.picture || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />

            <p>
                <span>{{ user?.email }}</span>
            </p>
        </li>
        <!-- Menu Body -->
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profil
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Se déconnecter</a
            >
        </li>
    </ng-container>
</app-dropdown>
