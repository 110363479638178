import {Pipe, PipeTransform} from '@angular/core';
import { UserRole } from '@core/models/models';
import {Role} from '@views/pages/profile/models/user.model';
import { RolesLabel } from './roleLabel.pipe';

@Pipe({
  name: 'mainRoleLabel'
})
export class MainRoleLabelPipe implements PipeTransform {

  transform(roles: Array<Role | UserRole>) {
    if (roles.includes(UserRole.ROLE_SUPER_ADMIN)) {
      return  RolesLabel[UserRole.ROLE_SUPER_ADMIN]
    }

    if (roles.includes(UserRole.ROLE_ADMIN)) {
      return  RolesLabel[UserRole.ROLE_ADMIN]
    }

    if (roles.includes(UserRole.ROLE_ORGANIZATION_ADMIN)) {
      return  RolesLabel[UserRole.ROLE_ORGANIZATION_ADMIN]
    }

    return RolesLabel[UserRole.ROLE_USER]
  }

}