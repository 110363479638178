import {Injectable} from '@angular/core';
import { Role } from '@views/pages/profile/models/user.model';
// @ts-ignore
import Cache from "coka-cachejs";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private dataCache: Cache;
  private securityCache: Cache = null;

  constructor(private cookie: CookieService) {
    this.dataCache = new Cache.SessionStorage(25, 3600, 'data');
  }

  public getSecurityCache(): Cache.SessionStorage|Cache.LocalStorage{
    if (null === this.securityCache) {
      this.securityCache = this.cookie.check('REMEMBER_ME') ? new Cache.LocalStorage(25, 0, 'security') : new Cache.SessionStorage(25, 0, 'security');
    }

    return this.securityCache;
  }

  public isAuthenticatedUser(): boolean {
    return this.getSecurityCache().has('user');
  }

  public getAuthenticatedUser(){
   return this.getSecurityCache().read('user');
  }

  public setAuthenticatedUser(user: any, rememberMe: boolean = false){
    if (null === this.securityCache) {
      this.securityCache = rememberMe ? new Cache.LocalStorage(25, 0, 'security') : new Cache.SessionStorage(25, 0, 'security');
    } else if (this.securityCache instanceof Cache.SessionStorage && rememberMe) {
      this.securityCache =  Cache.LocalStorage(25, 0, 'security');
    }

    this.securityCache.write('user', user);

    if (rememberMe) {
      this.cookie.set('REMEMBER_ME', '1');
    }

    return this;
  }

  public set(key: string, value: any) {
    this.dataCache.write(key, value);
    return this;
  }

  public get(key: string, defaultValue: any) {
    return this.dataCache.read(key, defaultValue);
  }

  public clear() {
    if (null !== this.securityCache) {
      this.securityCache.purge();
      this.securityCache = null;
    }

    this.dataCache.purge();
    this.cookie.delete('REMEMBER_ME');

    return this;
  }
}
