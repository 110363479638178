import {NgModule} from '@angular/core';

import {RouterModule} from "@angular/router";
import {BlankComponent} from "@views/admin-lte/pages/blank/blank.component";
import {ProfileComponent} from "@views/admin-lte/pages/profile/profile.component";
import {DashboardComponent} from "@views/admin-lte/pages/dashboard/dashboard.component";
import {SubMenuComponent} from "@views/admin-lte/pages/main-menu/sub-menu/sub-menu.component";
import {CommonModule} from "@angular/common";
import {MainMenuComponent} from "@views/admin-lte/pages/main-menu/main-menu.component";
import {DropdownMenuComponent} from "@views/admin-lte/components/dropdown/dropdown-menu/dropdown-menu.component";
import {ButtonComponent} from "@views/admin-lte/components/button/button.component";
import {MenuItemComponent} from "@views/admin-lte/components/menu-item/menu-item.component";
import {DropdownComponent} from "@views/admin-lte/components/dropdown/dropdown.component";


@NgModule({
  declarations: [
    BlankComponent,
    ProfileComponent,
    DashboardComponent,
    ButtonComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    DropdownComponent,
    DropdownMenuComponent
  ],
  exports: [
    ButtonComponent,
    BlankComponent,
    ProfileComponent,
    DashboardComponent,
    ButtonComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    DropdownComponent,
    DropdownMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class AdminLteModule { }
