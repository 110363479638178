import {HttpClient, HttpParams} from '@angular/common/http';

export abstract class ApiService {
  /**
   * GET verb action
   */

  get(endpoint: string, params?: any, reqOpts?: any, headers?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return endpoint
      ? this.getHttp().get(this.getUrl() + '/' + endpoint, !headers ? reqOpts : headers)
      : this.getHttp().get(this.getUrl(), !headers ? reqOpts : headers) ;
  }

  /**
   * POST verb action
   */
  post(endpoint: string, body: any, reqOpts?: any) {
    return this.getHttp().post(this.getUrl() + '/' + endpoint, body, reqOpts);
  }

  head(endpoint: string, reqOpts?: any) {
    return this.getHttp().head(this.getUrl() + '/' + endpoint, reqOpts);
  }

  /**
   * PUT verb action
   */
  put(endpoint: string, body: any, reqOpts?: any) {
    return endpoint
      ? this.getHttp().put(this.getUrl() + '/' + endpoint, body, reqOpts)
      : this.getHttp().put(this.getUrl(), body, reqOpts);
  }

  /**
   * PATCH verb action
   */
  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.getHttp().patch(this.getUrl() + '/' + endpoint, body, reqOpts);
  }

  /**
   * Request Verb for generic HTTP request
   * can be used to put body on all request verb
   */
  request(method: any, endpoint: string, reqOpts?: any) {
    return this.getHttp().request(method, this.getUrl() + '/' + endpoint,  reqOpts);
  }

  /**
   * DELETE verb action
   */
  delete(endpoint: string, reqOpts?: any) {
    return this.getHttp().delete(this.getUrl() + '/' + endpoint, reqOpts);
  }

  protected abstract getUrl(): string;
  protected abstract getHttp(): HttpClient;
}
