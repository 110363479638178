import {IDateTime} from '@shared/Models/dateTime.model';
import {IOrganization} from '@views/pages/organization/models/organization.model';

export interface IUser extends IDateTime {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    enabled: boolean;
    verified: boolean;
    locked: false;
    phoneNumber: string;
    passwordChanged: boolean;
    propertiesVerified: number;
    organization: IOrganization;
    roles: Role[];
}

export enum Role {
    ROLE_USER = "ROLE_USER",
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
    ROLE_ORGANIZATION = "ROLE_ORGANIZATION",
    ROLE_ORGANIZATION_ADMIN = "ROLE_ORGANIZATION_ADMIN",
    ROLE_ORGANIZATION_DEFAULTS = "ROLE_ORGANIZATION_DEFAULTS",
    ROLE_ORGANIZATION_BENEFIT = "ROLE_ORGANIZATION_BENEFIT",
    ROLE_ORGANIZATION_INSURANCE = "ROLE_ORGANIZATION_INSURANCE",
    ROLE_ORGANIZATION_CONTRIBUTION = "ROLE_ORGANIZATION_CONTRIBUTION",
    ROLE_ORGANIZATION_HEALTH_CENTER = "ROLE_ORGANIZATION_HEALTH_CENTER",
}