import {Component, OnInit} from '@angular/core';
import {MENU, MenuOrganization, ORGANIZATION_ADMIN_MENU, ORGANIZATION_MANAGEMENT_MENU} from "./menu/menu";
import {SessionService} from "@core/services/session.service";
import {Role} from "@views/pages/profile/models/user.model";

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user!: any;
    public menu: any;
    adminOrganizationItems = ORGANIZATION_MANAGEMENT_MENU;
  Role: typeof Role = Role;

    constructor(
      private sessionService: SessionService,
    ) {}

    ngOnInit() {
      this.user = this.sessionService.getAuthenticatedUser()?.user;
      if (this.user.roles.includes(Role.ROLE_SUPER_ADMIN) || this.user.roles.includes(Role.ROLE_ADMIN)) {
        this.menu = MENU;
        return;
      }

      if (this.user.roles.includes(Role.ROLE_ORGANIZATION_ADMIN)) {
        this.menu = ORGANIZATION_ADMIN_MENU;
        return;
      }

      this.menu = MenuOrganization;
    }

    get isAdminUSer() {
      return !!this.user && this.user.roles.includes(Role.ROLE_SUPER_ADMIN) || this.user.roles.includes(Role.ROLE_ADMIN)
    }
}
