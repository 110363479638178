import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CURRENT_USER_MICROSERVICE, LOGOUT_URL } from '@core/constants/api.constants';
import { DEFAULT_BUFFER_MAX_TIME } from '@core/constants/constants';
import {
  RequestParams,
  UserProfile,
  UserVerifiedStatuses,
} from '@core/models/models';
import { isBitMaskFlagSet } from '@core/utils/utils';
import { finalize, map, Observable, shareReplay } from 'rxjs';
import { SessionService } from '@core/services/session.service';
import { OrganizationService } from '@views/pages/organization/services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  cachedProfile$!: Observable<UserProfile>;
  constructor(private http: HttpClient, private router: Router, private sessionService: SessionService, private location: Location, private organizationService: OrganizationService) {}

  getProfile(params: RequestParams) {
    const { useCachedData = true, ...otherParams } = params;
    const request$ = this.http
      .get<UserProfile>(CURRENT_USER_MICROSERVICE, {
        params: otherParams,
      })
      .pipe(
        map((profile) => ({
          ...profile,
          haveEmailVerified: isBitMaskFlagSet(
            profile.propertiesVerified,
            UserVerifiedStatuses.email_verified
          ),
          havePhoneNumberVerified: isBitMaskFlagSet(
            profile.propertiesVerified,
            UserVerifiedStatuses.phone_number_verified
          ),
        }))
      );

    if (useCachedData && this.cachedProfile$) {
      return this.cachedProfile$;
    } else {
      this.cachedProfile$ = request$.pipe(
        shareReplay(2, DEFAULT_BUFFER_MAX_TIME)
      );
    }

    return request$;
  }

  updateProfile(profile: Partial<UserProfile>) {
    return this.http.patch<UserProfile>(CURRENT_USER_MICROSERVICE, profile);
  }

  logOut(redirectUrl?: string) {
    return this.http.get(LOGOUT_URL, {
      params: {
        skip: true
      }
    }).pipe(
        finalize(() => {
          this.organizationService.clearOrganizationData();
          this.sessionService.clear();
          this.router.navigate(['/se-connecter']);
          // this.location.go('/se-connecter', `returnUrl=${redirectUrl ?? this.router.url}`);
          // location.reload();
        })
      );
  }
}
