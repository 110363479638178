import {Role} from "@views/pages/profile/models/user.model";
import {SessionService} from "@core/services/session.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CheckUserRole {
  constructor(
    private sessionService: SessionService
  ) {}

  isOrganizationAdmin() {
    const user = this.sessionService.getAuthenticatedUser()?.user;
    const role = Role;
    return !!(this.sessionService.isAuthenticatedUser()
      && user?.roles.includes(role.ROLE_ORGANIZATION_ADMIN)
      && !user?.roles.includes(role.ROLE_SUPER_ADMIN)
      && !user?.roles.includes(role.ROLE_ADMIN)
      && user?.passwordChanged
    );
  }
}
