import {NgSelectModule} from '@ng-select/ng-select';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageTitleComponent} from "@shared/page-title/page-title.component";
import {FeahterIconModule} from "@core/directives/feather-icon/feather-icon.module";
import {PaginationComponent} from "@shared/pagination/pagination.component";
import {StatePipe} from "@shared/utils/pipes/state.pipe";
import {OrganizationTypePipe} from "@shared/utils/pipes/organization-type.pipe";
import {UpdatedDateFormatPipe} from "@shared/utils/pipes/updated-date-format.pipe";
import {FilterValuePipe} from "@shared/utils/pipes/filter-value.pipe";
import {CapitalizeLetterPipe} from "@shared/utils/pipes/capitalize-letter.pipe";
import {ContentAnimateDirective} from "@core/directives/content-animate/content-animate.directive";
import {ToasterContainerComponent} from "@shared/toaster-container/toaster-container.component";
import {NgbDatepickerModule, NgbDropdownModule, NgbToastModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {HasRolePipe} from './utils/pipes/has-role.pipe';
import {BitMaskPipe} from './utils/pipes/bitmask.pipe';
import {DateFormatPipe} from './utils/pipes/dateFormat.pipe';
import {RouterModule} from '@angular/router';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {AdminLteModule} from '@views/admin-lte/admin-lte.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GetColorForMethodPipe} from '@views/pages/audit-trail/pipes/get-color-for-method.pipe';
import {GetRoleName} from './utils/pipes/getRoleName.pipe';
import {FiltersComponent} from './components/filters/filters.component';
import {DateFilterComponent} from './components/date-filter/date-filter.component';
import { RolesMatrixComponent } from './components/roles-matrix/roles-matrix.component';
import { GetRoleLabelPipe } from './utils/pipes/roleLabel.pipe';
import { LoadingOrErrorComponent } from './components/loading-or-error/loading-or-error.component';
import { ChartComponent } from './components/chart/chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { TransactionStatusBadgeComponent } from './components/transaction-status-badge/transaction-status-badge.component';
import { MainRoleLabelPipe } from './utils/pipes/mainRoleLabel.pipe';
import { UseFunctionPipe } from './utils/pipes/useFunction.pipe';
import { ExportFormComponent } from './components/export-form/export-form.component';
import * as dayjs from 'dayjs';

@NgModule({
  declarations: [
    PaginationComponent,
    StatePipe,
    OrganizationTypePipe,
    UpdatedDateFormatPipe,
    DateFormatPipe,
    FilterValuePipe,
    CapitalizeLetterPipe,
    HasRolePipe,
    BitMaskPipe,
    GetRoleLabelPipe,
    PageTitleComponent,
    ToasterContainerComponent,
    ContentAnimateDirective,
    DeleteModalComponent,
    GetColorForMethodPipe,
    MainRoleLabelPipe,
    GetRoleName,
    FiltersComponent,
    DateFilterComponent,
    RolesMatrixComponent,
    LoadingOrErrorComponent,
    ChartComponent,
    ExportButtonComponent,
    TransactionStatusBadgeComponent,
    UseFunctionPipe,
    ExportFormComponent,
  ],
  exports: [
    PaginationComponent,
    FiltersComponent,
    DateFilterComponent,
    RolesMatrixComponent,
    StatePipe,
    OrganizationTypePipe,
    UpdatedDateFormatPipe,
    DateFormatPipe,
    FilterValuePipe,
    CapitalizeLetterPipe,
    BitMaskPipe,
    HasRolePipe,
    GetRoleLabelPipe,
    MainRoleLabelPipe,
    PageTitleComponent,
    ContentAnimateDirective,
    GetColorForMethodPipe,
    GetRoleName,
    NgApexchartsModule,
    LoadingOrErrorComponent,
    ChartComponent,
    ExportButtonComponent,
    TransactionStatusBadgeComponent,
    UseFunctionPipe,
    ExportFormComponent,
  ],
  imports: [
    CommonModule,
    FeahterIconModule,
    NgbToastModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AdminLteModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgApexchartsModule,
  ]
})
export class SharedModule {
}
