import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {SessionService} from "@core/services/session.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of error interceptor.
   * @param toaster [toasterService]
   * @param router [router]
   * @param sessionService
   * @param route [activatedRoute]
   */
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private sessionService: SessionService,
    private route: ActivatedRoute
  ) {
  }

  /**
   * Intercepts error interceptor
   * @param request [request]
   * @param next [next]
   * @returns intercept
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error) {
          let errorMsg: any;
          switch (error?.status) {
            case 401:
              if (true === this.sessionService.getAuthenticatedUser()) {
                this.router.navigateByUrl('/se-connecter');
              }
              errorMsg = error;
              break;
            case 400:
              errorMsg = error;
              break;
            case 0 :
              break;
            case 500 :
              this.toaster.error(`Une erreur inattendue est survenue sur le serveur`);
              break;
            default:
              errorMsg = error;
          }
          return throwError(() => errorMsg);
        } else {
          this.toaster.error(`Une erreur inattendue est survenue`);
        }

        /**
         * Return request error
         */
        return throwError(() => error);
      })
    );
  }
}
