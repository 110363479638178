import {Component, OnInit} from '@angular/core';
import { ProfileService } from '@core/services/profile/profile.service';
import {SessionService} from "@core/services/session.service";
import { Subject } from 'rxjs';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user!: any;
    private _destroy$ = new Subject<void>();

    constructor(
      private sessionService: SessionService,
      private profileService: ProfileService,
    ) {}

    ngOnInit(): void {
      this.user = this.sessionService.getAuthenticatedUser()?.user;
    }

    ngOnDestroy() {
      this._destroy$.next();
      this._destroy$.complete();
    }

    logout() {
      this.profileService.logOut().pipe().subscribe();
    }
}
