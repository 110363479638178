<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link flex items-center justify-between">
  <img src="assets/img/logo-cnam-horizontal.png" alt="CNAM" class="brand-image" />
  <!-- <img
    src="../../../../../assets/img/logo.png"
    alt="AdminLTE Logo"
    class="brand-image img-circle elevation-3"
    style="opacity: 0.8"
  /> -->
</a>

<!-- Sidebar -->
<div #sidebar class="sidebar">
  <!-- Sidebar user (optional) -->
  <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
    <div class="image">
      <img [src]="user?.picture || 'assets/img/default-profile.png'" class="img-circle elevation-2" alt="User Image" />
    </div>
    <div class="info">
      <a [routerLink]="['/profile']" class="d-block">
        {{ user?.email }}
      </a>
    </div>
  </div> -->

  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <p class="info px-3 mb-2">
        Général
      </p>
      <app-menu-item *ngFor="let item of menu" [menuItem]="item"></app-menu-item>
      <ng-container *ngIf="isAdminUSer">
        <p class="info px-3 mb-2 mt-4">
          Gestion
        </p>
        <app-menu-item *ngFor="let item of adminOrganizationItems" [menuItem]="item"></app-menu-item>
      </ng-container>
    </ul>
  </nav>
  <!-- <div class="light-sidebar-logo position-absolute d-flex justify-content-center align-items-center bottom-0">
    <a class="h1 text-center"><img src="assets/img/logo-cnam.png" class="img-fluid" width="75" alt=""></a>
  </div> -->
</div>