import {NgModule} from '@angular/core';
import {GuardsCheckEnd, NavigationEnd, RouterModule, Routes} from '@angular/router';
import {NgProgressRouterModule} from "ngx-progressbar/router";
import {MainComponent} from "@views/layout/main/main.component";
import {AuthGuard} from "@core/guards/auth.guard";


const routes: Routes = [
  {path: 'se-connecter', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'utilisateurs', loadChildren: () => import('./views/pages/users/users.module').then(m => m.UsersModule)},
      {path: 'organisation', loadChildren: () => import('./views/pages/organization/organization.module').then(m => m.OrganizationModule)},
      {path: 'referentiels', loadChildren: () => import('./views/pages/repository/repository.module').then(m => m.RepositoryModule)},
      {path: 'audit-trail', loadChildren: () => import('./views/pages/audit-trail/audit-trail.module').then(m => m.AuditTrailModule)},
      {path: 'profile', loadChildren: () => import('./views/pages/profile/profile.module').then(m => m.ProfileModule)},
      {path: 'transmissions', loadChildren: () => import('./views/pages/transmissions/transmissions.module').then(m => m.TransmissionsModule)},
      {path: 'flux', loadChildren: () => import('./views/pages/flow/flow.module').then(m => m.FlowModule)},
      {path: 'dashboard', loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)},
      {path: 'adjudications', loadChildren: () => import('./views/pages/adjudications/adjudications.module').then(m => m.AdjudicationsModule)},
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    NgProgressRouterModule.withConfig({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
      delay: 1000,
      id: 'router-progressbar'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
