import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgProgressModule} from "ngx-progressbar";
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from "@angular/common";
import {ToastrModule} from "ngx-toastr";
import {LayoutModule} from "@views/layout/layout.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgProgressHttpModule} from "ngx-progressbar/http";
import {PasswordStrengthCheckerModule} from '@diama/password-strength-checker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from "@angular/material/tooltip";
import {ErrorInterceptor} from '@core/interceptors/error-interceptor';
import {JwtInterceptor} from "@core/interceptors/jwt-interceptor";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {DragDropModule} from "@angular/cdk/drag-drop";

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgProgressModule.withConfig({
      trickleSpeed: 200,
      min: 20,
      meteor: false,
      spinner: false,
      color: '#009BDD'
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    NgbModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    NgProgressHttpModule,
    PasswordStrengthCheckerModule,
    NgSelectModule,
    DragDropModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
