import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStroke,
  ApexTheme,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

export type UserProfile = {
  id: string;
  enabled: boolean;
  locked: boolean;
  propertiesVerified: number;
  email: string;
  phoneNumber: string;
  passwordChanged: boolean;
  createdAt: string;
  updatedAt: string;
  username: string;
  roles: Array<UserRole>;
  firstName?: string;
  lastName?: string;
  haveEmailVerified?: boolean;
  havePhoneNumberVerified?: boolean;
  organization?: any;
};

export enum UserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ORGANIZATION = 'ROLE_ORGANIZATION',
  ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
  ROLE_ORGANIZATION_DEFAULTS = 'ROLE_ORGANIZATION_DEFAULTS',
  ROLE_ORGANIZATION_BENEFIT = 'ROLE_ORGANIZATION_BENEFIT',
  ROLE_ORGANIZATION_INSURANCE = 'ROLE_ORGANIZATION_INSURANCE',
  ROLE_ORGANIZATION_CONTRIBUTION = 'ROLE_ORGANIZATION_CONTRIBUTION',
  ROLE_ORGANIZATION_HEALTH_CENTER = 'ROLE_ORGANIZATION_HEALTH_CENTER',
  ROLE_ALLOWED_TO_SWITCH = ' ROLE_ALLOWED_TO_SWITCH',
}

export enum UserVerifiedStatuses {
  email_verified = 1,
  phone_number_verified = 2,
}

export type RequestParams = {
  itemPerPage?: number;
  page?: number;
  orderBy?: {
    createdAt?: 'asc' | 'desc';
  };
  useCachedData?: boolean;
  [key: string]: any;
};

export type Organization = {
  id?: string;
  type: any;
  name: string;
  email: string;
  phoneNumber: string;
  enabled: boolean;
  username: string;
  roles: UserRole[];
  createdAt: string;
  updatedAt: string;
};

export type FormViolation = {
  propertyPath: string;
  title: string;
  type: string;
};

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart?: ApexChart;
  colors?: any[];
  responsive?: ApexResponsive[];
  labels?: string[];
  stroke?: ApexStroke;
  fill?: ApexFill;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  plotOptions?: ApexPlotOptions;
  tooltip?: ApexTooltip;
  legend?: ApexLegend;
  dataLabels?: ApexDataLabels;
  theme?: ApexTheme;
};

export enum PeriodDateFilter {
  RANGE = 'RANGE',
  WEEK = 'WEEK',
  MOUNTH = 'MOUNTH',
  TODAY = 'TODAY',
  DATE = 'DATE',
  YESTERDAY = 'YESTERDAY',
}