import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {BehaviorSubject, catchError, filter, first, map, Observable, switchMap, throwError} from "rxjs";
import {SessionService} from "@core/services/session.service";
import {CookieService} from 'ngx-cookie-service';
import {environment} from "@environments/environment";
import { Router } from '@angular/router';
import { ProfileService } from '@core/services/profile/profile.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing: Boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private sessionService: SessionService,
    private http: HttpClient,
    private cookie: CookieService,
    private router: Router,
    private profileService: ProfileService,
    private toastr: ToastrService,
  ) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let user = this.sessionService.getAuthenticatedUser();
    const skipInterceptor = request.params.get('skip');

    if (skipInterceptor) {
      return next.handle(request);
    }

    if (user) {
      request = this.addToken(request, user.tokens.accessToken.id)
    }

    return next.handle(request)
    .pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && 401 === error.status && user) {
          return this.refreshToken(request, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }


  private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const tokens = this.sessionService.getAuthenticatedUser().tokens

      const data = {
        "identity": {
            "methods": [
              "refreshToken"
          ],
          "refreshToken": {
            "token": {
              "id": tokens.refreshToken.id
            }
          }
        }
      };

      return this.login(data).pipe(
        switchMap((response: any) => {
          if (!!tokens) {
            this.isRefreshing = false;

            const REMEMBER_ME = this.cookie.get('REMEMBER_ME');

            this.sessionService.clear()
            .setAuthenticatedUser(response, '1' === REMEMBER_ME);

            const jwt = response.tokens.accessToken.id;
            this.refreshTokenSubject.next(jwt);
            return next.handle(this.addToken(request, jwt));
          }
          return next.handle(request);
        }),
        catchError(error => {
          this.isRefreshing = false;
          if (error instanceof HttpErrorResponse && 401 === error.status) {
            this.toastr.success('Votre session a expirée. Veuillez vous reconnecter');
            return this.profileService.logOut(this.router.url) as Observable<any>;
          }
          return throwError(() => error)
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        first(),
        switchMap(jwt => next.handle(this.addToken(request, jwt)))
      );
    }
  }


  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    })
  }

  public login(credentials: any): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders({
        'X-Application-Id' : environment.appId,
      }),
      params: {
        skip: true
      }
    };
    return this.http.post(`${environment?.apiBaseUrl}/identity/v1/rest/auth/tokens`, credentials, requestOptions).pipe(
      map((response: any) => response),
      catchError(error => throwError(() => error))
    )
  }
}
