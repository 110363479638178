import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MainComponent} from "@views/layout/main/main.component";
import {HeaderComponent} from "@views/layout/main/header/header.component";
import {LanguageComponent} from "@views/layout/main/header/language/language.component";
import {MenuSidebarComponent} from "@views/layout/main/menu-sidebar/menu-sidebar.component";
import {AdminLteModule} from "@views/admin-lte/admin-lte.module";
import {FooterComponent} from "@views/layout/main/footer/footer.component";
import {UserComponent} from "@views/layout/main/header/user/user.component";
import {NotificationsComponent} from "@views/layout/main/header/notifications/notifications.component";
import {MessagesComponent} from "@views/layout/main/header/messages/messages.component";
import {SharedModule} from "@shared/shared.module";
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    MainComponent,
    FooterComponent,
    HeaderComponent,
    MenuSidebarComponent,
    LanguageComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgbTooltipModule,
        AdminLteModule,
        SharedModule
    ]
})
export class LayoutModule { }
