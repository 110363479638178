import { FormViolation } from '@core/models/models';

/**
 * Get an object including updated properties
 * between two object
 */
export const getUpdatedProperties = (
  oldObject: Record<string, unknown> | undefined | null,
  newObject: Record<string, unknown> | undefined | null,
  matchOnlySharedProperties = true
) => {
  if (!oldObject || !newObject) {
    return null;
  }
  const updatedProperties: Record<string, unknown> = {};
  if (matchOnlySharedProperties) {
    Object.keys(newObject).forEach((key) => {
      if (
        Object(oldObject).hasOwnProperty(key) &&
        newObject[key] !== oldObject[key]
      ) {
        updatedProperties[key] = newObject[key];
      }
    });
  } else {
    Object.keys(newObject).forEach((key) => {
      if (newObject[key] !== oldObject[key]) {
        updatedProperties[key] = newObject[key];
      }
    });
  }

  return Object.keys(updatedProperties).length ? updatedProperties : null;
};

export const isBitMaskFlagSet = (bitMaskValue: number, valueToTest: number) =>
  (bitMaskValue & valueToTest) == valueToTest;

export const formatHttpViolations = (
  violations: FormViolation[] | undefined
) => {
  return !!violations && violations.length
    ? violations.map((el) => {
        if (el.propertyPath) {
          el.propertyPath = el.propertyPath.replace('[', '').replace(']', '');
        }

        return el;
      })
    : [];
};

export const removeEmptyProperties = (object: Record<string, any>, useAdditionalFilters = {
  arrays: false,
  strings: false
}) => {
  const { arrays, strings } = useAdditionalFilters;
  const result: Record<string, any> = {};
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      if ((!!arrays && object[key].length !== 0)) {
        result[key] = object[key];
      } else {
        result[key] = object[key];
      }
      return;
    }

    if (typeof object[key] === 'string') {
      if (!!strings && !!object[key]) {
        result[key] = object[key];
      } else if (!strings) {
        result[key] = object[key];
      }
      return;
    }
    if (typeof object[key] === 'number') {
      result[key] = object[key];
      return;
    }

    if (typeof object[key] === 'boolean') {
      result[key] = object[key];
      return;
    }

    if (typeof object[key] === 'object' && !!object[key]) {
      const deepObject = removeEmptyProperties(object[key], useAdditionalFilters);
      if (Object.keys(deepObject).length) {
        result[key] = deepObject;
      }
      return;
    }
  });
  
  return result;
};
